var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"descriptor-check-container mt-1"},[(_vm.user_id != null && _vm.evaluatee_view)?_c('div',{staticClass:"descriptor-checkbox",class:{
          'container-check-rubric':
            _vm.is_published && _vm.score_selected_evaluator == true,
        }},[(
            _vm.coevaluator_id == null &&
            _vm.countRubricScoreEvaluator.length > 0 &&
            _vm.is_published
          )?_c('div',[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `${
                _vm.rubric.evaluatee_can_see_coevaluator
                  ? _vm.countRubricScoreEvaluator.length > 1
                    ? 'Coevaluadores: ' + _vm.getUsersRubricScoreEvaluatos
                    : 'Coevaluador: ' + _vm.getUsersRubricScoreEvaluatos
                  : ''
              }`
            ),expression:"\n              `${\n                rubric.evaluatee_can_see_coevaluator\n                  ? countRubricScoreEvaluator.length > 1\n                    ? 'Coevaluadores: ' + getUsersRubricScoreEvaluatos\n                    : 'Coevaluador: ' + getUsersRubricScoreEvaluatos\n                  : ''\n              }`\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"mr-2",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.countRubricScoreEvaluator.length)+" ")])],1):_vm._e(),(
            _vm.score_selected_evaluator == true &&
            _vm.is_published &&
            ((_vm.institution && _vm.institution.internal_use_id != 'duoc_uc') ||
              (_vm.evaluative_agent &&
                !(
                  [
                    'duoc_coevaluacion_evaluative_agent',
                    'duoc_autoevaluacion_evaluative_agent',
                  ].includes(_vm.evaluative_agent.internal_use_id) ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación') ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Autoevaluación')
                )))
          )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.left.noninteractive",value:(
            `Respuesta del Docente`
          ),expression:"\n            `Respuesta del Docente`\n          ",modifiers:{"v-secondary":true,"left":true,"noninteractive":true}}],staticClass:"mr-3",attrs:{"icon":"check-square","variant":"success","scale":"1.2"}}):_vm._e(),(
            _vm.coevaluator_id != null &&
            (_vm.test.student_can_auto_evaluate ||
              (_vm.evaluative_agent &&
                (['duoc_coevaluacion_evaluative_agent'].includes(
                  _vm.evaluative_agent.internal_use_id
                ) ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación'))))
          )?_c('b-checkbox',{attrs:{"disabled":_vm.score_selected_evaluatee == true ||
            !_vm.is_running_test ||
            _vm.finished_test ||
            _vm.is_published},on:{"change":_vm.saveRubricScore},model:{value:(_vm.score_selected_evaluatee),callback:function ($$v) {_vm.score_selected_evaluatee=$$v},expression:"score_selected_evaluatee"}}):_vm._e()],1):_vm._e(),(_vm.evaluator_view && _vm.user_id != null)?_c('div',{staticClass:"descriptor-checkbox"},[(
            _vm.coevaluator_id == null && _vm.countRubricScoreEvaluator.length > 0
          )?_c('div',[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `${
                _vm.countRubricScoreEvaluator.length > 1
                  ? 'Coevaluadores: ' + _vm.getUsersRubricScoreEvaluatos
                  : 'Coevaluador: ' + _vm.getUsersRubricScoreEvaluatos
              }`
            ),expression:"\n              `${\n                countRubricScoreEvaluator.length > 1\n                  ? 'Coevaluadores: ' + getUsersRubricScoreEvaluatos\n                  : 'Coevaluador: ' + getUsersRubricScoreEvaluatos\n              }`\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"mr-2",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.countRubricScoreEvaluator.length)+" ")])],1):_vm._e(),_c('div',{class:{
            'container-check-rubric':
              (_vm.test.student_can_auto_evaluate ||
                (_vm.evaluative_agent &&
                  (['duoc_coevaluacion_evaluative_agent'].includes(
                    _vm.evaluative_agent.internal_use_id
                  ) ||
                    _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación')))) &&
              _vm.score_selected_evaluatee == true,
          }},[(_vm.score_selected_evaluatee == true)?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.left.noninteractive",value:(
              `
              ${
                _vm.evaluative_agent &&
                (['duoc_coevaluacion_evaluative_agent'].includes(
                  _vm.evaluative_agent.internal_use_id
                ) ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación'))
                  ? 'Respuesta del Coevaluador'
                  : 'Respuesta del Evaluado'
              }`
            ),expression:"\n              `\n              ${\n                evaluative_agent &&\n                (['duoc_coevaluacion_evaluative_agent'].includes(\n                  evaluative_agent.internal_use_id\n                ) ||\n                  $equals(evaluative_agent.name, 'Coevaluación'))\n                  ? 'Respuesta del Coevaluador'\n                  : 'Respuesta del Evaluado'\n              }`\n            ",modifiers:{"v-secondary":true,"left":true,"noninteractive":true}}],staticClass:"mr-3",attrs:{"icon":"check-square","variant":"success","scale":"1.2"}}):_vm._e(),(
              (_vm.institution && _vm.institution.internal_use_id != 'duoc_uc') ||
              (_vm.evaluative_agent &&
                !(
                  [
                    'duoc_coevaluacion_evaluative_agent',
                    'duoc_autoevaluacion_evaluative_agent',
                  ].includes(_vm.evaluative_agent.internal_use_id) ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación') ||
                  _vm.$equals(_vm.evaluative_agent.name, 'Autoevaluación')
                ))
            )?_c('b-checkbox',{staticClass:"checkbox-check-rubric",attrs:{"disabled":_vm.score_selected_evaluator == true || !_vm.closedEvaluationScore},on:{"change":_vm.saveRubricScore},model:{value:(_vm.score_selected_evaluator),callback:function ($$v) {_vm.score_selected_evaluator=$$v},expression:"score_selected_evaluator"}}):_vm._e()],1)]):_vm._e()]),(
        _vm.preview_evaluation &&
        (_vm.test.student_can_auto_evaluate ||
          (_vm.evaluative_agent &&
            (['duoc_coevaluacion_evaluative_agent'].includes(
              _vm.evaluative_agent.internal_use_id
            ) ||
              _vm.$equals(_vm.evaluative_agent.name, 'Coevaluación')))) &&
        !_vm.evaluatee_view &&
        !_vm.evaluator_view
      )?_c('div',[_c('b-checkbox',{staticClass:"checkbox-check-rubric",attrs:{"disabled":_vm.score_selected_preview_evaluation},on:{"change":_vm.previewEvaluationChange},model:{value:(_vm.score_selected_preview_evaluation),callback:function ($$v) {_vm.score_selected_preview_evaluation=$$v},expression:"score_selected_preview_evaluation"}})],1):_vm._e(),(_vm.rubric.allow_criteria_performance_level_input)?_c('div',{staticClass:"descriptor-div"},[(_vm.allows_crud)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:('Editar Descripción'),expression:"'Editar Descripción'",modifiers:{"v-secondary":true}}],staticClass:"rich-text-content mb-1",class:{
            'cell-form-description-with-content':
              _vm.rubric_description.title.length > 0,
            'cell-form-description': _vm.rubric_description.title.length == 0,
          },domProps:{"innerHTML":_vm._s(_vm.rubric_description.title)},on:{"click":_vm.openModalRubricPerformanceLevel}}),_c('b-modal',{attrs:{"id":`edit-cell-description-modal-${_vm.rubric_description.id}-${_vm.container_instrument_id}`,"title":"Editar Descripción de la Evaluación","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"},on:{"hide":_vm.hideModaltoMatterProgram}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.rubric_description,"Text":_vm.rubric_description.title},on:{"save":_vm.patchRubricDescriptorCell,"close":function($event){return _vm.$bvModal.hide(
                `edit-cell-description-modal-${_vm.rubric_description.id}-${_vm.container_instrument_id}`
              )}}})],1)]:[_c('div',{staticClass:"rubric-descriptor-cell rich-text-content",domProps:{"innerHTML":_vm._s(_vm.rubric_description.title)}})]],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }